html,
body,
:root {
	font-family: Roboto, sans-serif;
	font-size: 16px;
}

#wrapper {
	display: flex;
}

#wrapper aside * {
	box-sizing: border-box;
}

main {
	padding: 0 2rem;
	max-width: 540px;
}

fieldset,
button {
	margin: 0;
}

input,
button,
select {
	width: 100%;
	padding: 0.2rem;
}

button {
	border: none;
	background: rgb(69, 40, 151);
	color: white;
	padding: 0.25rem;
	cursor: pointer;
	border-radius: 0 0 1em 1em;
}

button:hover {
	background: rgb(55, 37, 105);
}
